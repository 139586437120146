@import '../../../media-queries.scss';

// ===================================================
// Carousel

.carousel.carousel-slider {
  padding-bottom: 60px;

  .control-dots {
    .dot {
      width: 9px;
      height: 9px;
    }
  }
}

// ===================================================
// Featured item

.item-featured-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  overflow: hidden;
  position: relative;
  background-color: #111018;
  border-radius: 20px;

  max-width: 100%;
  width: 100%;
  max-height: 520px;

  padding: 20px;

  .picture-container {
    position: relative;
    border-radius: 20px;
    width: 65%;
    overflow: hidden;
    margin-top: -20px;

    // height: 100%;
    .picture {
      //height: 100%;
      height: calc(100% - 40px);
      padding: 30px 0;
      border-radius: 30px;

      .overlay {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center left;
        height: 100%;
        border-radius: 20px;
      }
    }

    .tag {
      left: 37px;
      top: 50px;
      scale: 1.25;
    }
  }

  .content {
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    color: white;
    text-align: left;
    padding: 10px 20px 22px 20px;
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
    width: 29%;
    padding: 15% 36px;
    scale: 1.25;
    margin-left: 30px;

    .title {
      font-size: 25px;
      font-weight: 700;
    }

    .user {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .avatar {
        background-size: contain;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .name {
        font-size: 15px;
        line-height: 2;
        text-decoration: underline;
        width: 70%;
      }
    }

    .bottom {
      display: flex;
      flex-direction: row;
      gap: 5px;
      margin-top: 50px;

      .labels {
        width: calc(100% - 120px);
        font-size: 12px;
        line-height: 1.5;
        color: #84829d;
        margin-top: 4px;
        padding-left: 30px;
        padding-top: 7px;

        .mint {
          color: white;
          font-weight: 700;
        }
        .coll {
          color: #84829d;
        }
      }

      .button-blue {
        width: 120px;
        white-space: nowrap;
      }
    }

    .button {
      &.black {
        background: black;

        &:hover {
          color: white;
        }
        &:disabled {
          opacity: 0.5;
          color: #222;
          cursor: default;

          &:hover {
            color: #ccc;
          }
        }
      }

      width: 100%;
      margin: 0 auto;
      border-radius: 12px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 17px;
      padding: 15px 30px;
      color: #ccc;
      box-sizing: border-box;
      text-decoration: none;
      box-shadow: none;
    }
  }
}

@include respond-below(lg) {
  .item-featured-container {
    .picture-container {
      width: 60%;
    }
    .content {
      width: 35%;
    }
  }
}

@include respond-below(md) {
  .item-featured-container {
    aspect-ratio: 1/0.65;
    justify-content: center;

    .picture-container {
      width: 100%;
      height: 55%;
      margin: 0;
      .picture {
        padding: 0;
        height: 100%;
      }
      .tag {
        top: 22px;
      }
    }
    .content {
      width: 90%;
      padding: 0;
      padding: 40px 40px;
      margin-left: 0;

      .bottom {
        margin-top: 0;
      }
    }
  }
}

@include respond-below(sm) {
  .item-featured-container {
    aspect-ratio: 1/1.15;
    justify-content: center;

    .picture-container {
      width: 100%;
      height: 55%;

      .picture {
        padding: 0;
        height: 100%;
      }
    }

    .content {
      width: 90%;
      padding: 0;
      margin-left: 0;
      margin-top: 12px;
      scale: 1;

      .bottom {
        margin-top: 5px;
      }
    }
  }
}
