@import '../../../media-queries.scss';

.banner {
  position: relative;
  margin: 35px 0;
  width: 100%;
  aspect-ratio: 1/0;

  border-radius: 40px;
  border: 2px solid #3f3e48;
  overflow: hidden;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .interactive {
    display: flex;
    flex-direction: row;

    img {
      position: absolute;
      width: auto;
    }

    .left {
      // background-color: rgba(255, 0, 0, 0.5);
      width: 50%;
      height: 100%;
      min-height: 260px;
      position: relative;
      img {
        left: auto;
        right: 36%;
        bottom: -73%;
        height: 457px;
      }
    }

    .right {
      // background-color: rgba(0, 255, 255, 0.5);
      width: 50%;
      height: 100%;
      min-height: 260px;
      position: relative;
      img {
        height: 502px;
        bottom: -87.5%;
        left: 34%;
      }
    }
  }

  .content {
    position: absolute;
    top: 0;
    width: 100%;
    pointer-events: none;

    font-family: 'Inter', sans-serif;
    font-size: 25px;
    line-height: 1.3;
    color: white;
    text-align: center;

    padding: 15px 15px;

    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 16px;

    .title {
      font-size: 35px;
      font-weight: 700;
    }
    .message {
      font-size: 17px;
      white-space: pre-wrap;
      font-weight: 400;
      margin-top: -15px;
      margin-bottom: 0;
    }
    .footer {
      font-size: 18px;
      font-weight: 700;
      font-style: italic;
    }

    .buttons {
      p {
        margin: 0 0 5px 0;
        font-size: 15px;
        font-weight: 700;
      }
      .button-blue {
        width: 90px;
        padding: 10px 0;
        margin: 9px 30px 0;
      }
    }
  }
}

@include respond-below(md) {
  .banner {
    .interactive {
      .left img {
        bottom: -51%;
        height: 320px;
        right: 47%;
      }

      .right img {
        height: 380px;
        bottom: -67%;
      }
    }
  }
}

@include respond-below(sm) {
  .banner {
    max-width: 100%;
    aspect-ratio: 1/1.09;
    background-position: 30% 100%;

    .interactive {
      height: 100%;
      .left {
        height: 100%;
        img {
          height: 300px;
          right: 5%;
          top: 55%;
        }
      }

      .right {
        height: 100%;
        img {
          height: 330px;
          left: 0%;
          top: 49%;
        }
      }
    }

    .content {
      margin-top: 20px;
      padding: 15px 5px;
      .title {
        line-height: 1;
        width: 300px;
        font-size: 33px;
        font-weight: 700;
        margin: 0 auto;
        text-align: center;
      }
      .buttons {
        white-space: nowrap;
        p {
          font-size: 14px;
          margin-bottom: 10px;
        }
        .button-blue {
          width: 90px;
          font-size: 14px;
          padding: 10px 0;
          margin: 0 22px;
        }
      }
      .message {
        font-size: 15px;
        font-weight: normal;
        margin-top: -5px;
      }
      .footer {
        width: 145px;
        margin: -5px auto 0;
        font-size: 14px;
      }
    }
  }
}
