@import '../../media-queries.scss';

.mint-subpage {
  .mint-video-layout-video {
    position: relative;
  }
  .vimeo-react-player {
    width: 100%;
    height: 100%;

    iframe {
      width: auto;
      height: 100%;
      margin: 0 auto;
    }
  }

  > .transitioning-section {
    max-width: 700px;
    margin: 50px auto 0;
  }
}

.mint-login-btn-con {

  .notice {
    font-size: 14px;
    color: #6c6a85;
    margin-top: 30px;
    margin-bottom: 0;

    a, a:link, a:visited {
      color: #6c6a85;
      text-decoration: underline;

      &:hover {
        color: white;
      }
    }
  }
  .error-message {
    font-size: 14px;
    color: #ff7777;
    margin-top: 30px;
  }
  .metamask-btn {
    width: 100%;
    display: block;
    &:disabled {
      background-color: black;
      color: gray;
      box-shadow: 0 0 0 2.5px gray;
    }
  }
}

.mint-state-page-layout {
  .mint-video-children-con {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .GradientTitle {
    font-size: 32px;
    line-height: 1.3em;
    display: block;
  }
  .mint-btn-cta {
    display: block;
  }
}

.mintentry-body {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;

  .nft-con {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;

    .nft {
      border: 1px solid #292837;
      border-radius: 22px;
      padding: 30px;
      box-sizing: border-box;
      width: 300px;
      position: relative;
      margin: 0;

      img {
        display: block;
        border-radius: 11px;
        width: 100%;
        aspect-ratio: 1;
      }

      .no-image {
        border-radius: 11px;
        box-sizing: border-box;
        background-color: #222126;
        width: 100%;
        aspect-ratio: 1;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin: 0;
          padding: 0;
          color: #949494;
        }
      }

      figcaption {
        margin: 30px 11px 0;
        color: white;
        font-size: 13px;
        font-weight: 700;
      }
    }
  }

  .signup-con {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 630px;

    .svs-input-text {
      flex-grow: 1;
    }
  }

  .error-notice-con {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #ff6d6d;
    text-align: center;
  }

  .buttons-con {
    width: 100%;
    position: relative;

    button {
      display: block;
      width: 100%;
    }
  }

  .notice-con {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #6C6A85;

    a, a:visited, a:link {
      color: #463BF5;
    }
    a:hover {
      color: white;
    }
  }

  @include respond-below(md) {
    gap: 70px;
    .nft-con {
      flex-flow: column;
      flex-wrap: nowrap;
      gap: 50px;
  
      .nft {
        padding: 30px;
        box-sizing: border-box;
        width: 100%;
        position: relative;

        figcaption {
          font-size: 1em;
        }
      }
    }
    .signup-con {
      flex-flow: column;
      width: 100%;

      .svs-input-text {
        width: 100%;
      }
    }
  }
}

.mintentry-state-page-loading {
  .GradientTitle {
    text-align: center;
    font-size: 32px;
    margin: 0 auto;
    display: block;
  }
  .transitioning-section {
    max-width: 700px;
    margin: 70px auto 0;
  }
}
.mintshare-state-page {
  .mint-layout-header {
    justify-content: center;
    margin-bottom: 20px;
  }
  .mint-layout-title {
    text-align: center;
  }
  .mint-layout-subtitle {
    text-align: center;
  }
  .mint-layout-body {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .mint-video-content {
    border-radius: 20px;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    max-width: 600px;
    margin: 0 auto;
  }
  .notice-con {
    margin: 30px auto 0;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-align: center;

    .notice {
      color: #6C6A85;
      min-height: 1.1em;
      margin: 0 auto;
    }
    .error-notice {
      font-size: 14px;
      color: #ff6d6d;
      margin: 0 auto;
    }
  }
  .mint-share-btn {
    width: 100%;
    max-width: 400px;
    margin: 30px auto 0;
  }
  .back-btn {
    margin-top: 60px;
    align-self: flex-start;
  }
}

.minting-state-page {
  .GradientTitle {
    display: block;
    text-align: center;
  }
  .transitioning-section {
    max-width: 700px;
    margin: 70px auto 0;
  }
}

.minted-state-page {
  .mint-layout-header {
    text-align: center;
    justify-content: center;
  }
  .mint-layout-title {
  }
  .minted-body {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: fit-content;
    max-width: 100%;
    margin: 0 auto;
  }
  .description {
    padding-top: 30px;
  }
  .center {
    text-align: center;
  }
  
  .video-300 {
    height: 300px;
    aspect-ratio: 16 / 9;

    iframe {
      border-radius: 20px;
      overflow: hidden;
    }
  }
  
  .bottomText {
    font-size: 22px;
    margin-top: -10px;
  }
  
  .btn-buy-now {
    display: block;
    padding: 15px 60px;
    margin: 0 auto;
    width: fit-content;
  }

  .error-notice {
    text-align: center;
    max-width: 530px;
    color: #ff6d6d;
    margin: 0 auto;
  }
}
