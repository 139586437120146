@import '../../media-queries.scss';

.home-layout-items {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  height: auto;
  align-items: center;
  align-items: flex-start; /* By using align-items, you make sure that your .item won't stretch */
}

.home-layout-column-title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 30px;
  color: #ffffff;
  margin: 0 5px 5px;
  clear: both;
  width: 100%;

  &.live-header {
    margin-bottom: 30px;
  }
}
// ===================================================
// Button Blue

.button-blue {
  background: #4598fa;

  &:hover {
    color: white;
  }
  &:disabled {
    opacity: 0.5;
    color: white;
    cursor: default;

    &:hover {
      color: white;
    }
  }

  // width: 100%;
  margin: 0 auto;
  border-radius: 60px; // 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px; // 14px;
  line-height: 17px;
  padding: 10px 20px;
  color: white;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: none;
}

// ===================================================
// Button Border

.button-border {
  background: transparent; //  #4598fa;
  border: 2px solid white;

  &:hover {
    color: white;
  }
  &:disabled {
    opacity: 0.5;
    color: white;
    cursor: default;

    &:hover {
      color: white;
    }
  }

  width: 190px;
  margin: 0 9px; // auto;
  border-radius: 60px; // 12px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px; // 14px;
  line-height: 17px;
  padding: 10px 20px;
  color: white;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: none;
}

.carousel .control-arrow {
  display: none;
}

@include respond-below(sm) {
  .home-layout-column-title {
    font-size: 25px;
    margin-bottom: 0;
    &.live-header {
      margin-bottom: 15px;
    }
  }
  .home-layout-items {
    gap: 15px;
    .item-container {
      margin-bottom: 15px;
    }
  }
}
