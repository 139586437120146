@import '../../../media-queries.scss';
.arrow-icon {
  color: transparent;
  width: 24px;
  margin-top: -1px;
  filter: invert(1);
}

// prevent arrow from determining height of container
.arrow-wrapper {
  display: inline-block;
  height: 1em;
}

.connect-btn {
  background: linear-gradient(91.12deg, #6347f6 0.28%, #2b30f5 92.14%);
  border-radius: 0 0 8px 8px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  padding: 15px 30px;
  color: #f2f0ff;
  box-sizing: border-box;
  text-decoration: none;
  box-shadow: none;
  width: 100%;
  text-align: center;

  cursor: pointer;
  user-select: none;

  .label {
    margin-right: 5px;
    font-weight: 700;
    color: white;
    font-family: 'Inter', sans-serif;
    letter-spacing: -0.02em;
    line-height: 20px;
  }

  &:hover {
    color: #f2f0ff;
  }
  &:disabled,
  &.disabled {
    opacity: 0.5;
    color: #a7a7a7;
    cursor: default;

    .label {
      color: #a7a7a7;
    }

    .arrow-icon {
      filter: invert(1) opacity(0.5);
    }

    &:hover {
      color: #a7a7a7;
    }
  }

  &.success {
    background: #00ffe0;
    color: black;

    &:disabled,
    &.disabled {
      opacity: 1;
      cursor: default;

      &:hover {
        color: black;
      }
    }
  }
}

.connected-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0px;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
  -webkit-box-pack: end;
  // justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;

  .label {
    margin: 0px 0px 0px 5px;
    font-weight: 400;
    color: rgb(0, 208, 8);
    font-family: 'Inter', sans-serif;
    letter-spacing: -0.02em;
    font-size: 16px;
    line-height: 20px;
  }
}

.step-content {
  box-sizing: border-box;
  margin: 0px 0px 0px 10px;
  flex-direction: row;
  // flex-basis: 66.6667%;
  // -webkit-box-flex: 0;
  flex-grow: 1;
  // max-width: 66.6667%;
}

.step-title {
  margin: 0px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  font-family: 'Inter', sans-serif;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-size: 20px;
  color: #7848ff;
  a {
    text-decoration: underline;
  }
}

.step-label-container {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;

  .label {
    margin: 3px 0px 0px;
    font-weight: 400;
    color: #3cc748;
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.02em;
    font-size: 14px;
    line-height: 20px;
  }
}

.step-icon {
  color: transparent;
  width: 40px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.step-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-bottom: 25px;
  // padding-bottom: px;
  // padding-right: 8px;

  &.done {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    .step-content-wrapper {
      border: 1px solid #595959; // none
      border-bottom-style: none;
    }
  }

  &.not-done {
    .step-content-wrapper {
      border: 1px solid #595959; // none
      border-bottom-style: none;
    }
    box-shadow: none;
  }
}

.step-content-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  width: 100%;
  margin: 0px;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
  -webkit-box-align: center;
  align-items: center;
  padding: 24px 24px 16px 16px;
  background-color: black;
  border-radius: 8px 8px 0 0;
}

.submit-arrow {
  float: right;
  margin-right: -12px;
}

.step-disabled {
  .step-content-wrapper {
    background-color: #191919;
    opacity: 0.7;
    * {
      filter: invert(0.5);
      // opacity: 0.7;
    }
  }
}

.step-icon #Color {
  fill: #2b30f5;
}

.lgreen {
  color: #3cc748 !important;
}

.lgrey {
  color: #d4d2ed !important;
}

@include respond-below(md) {
  .step-title {
    margin: 0px;
    font-weight: 400;
    color: rgb(0, 0, 0);
    font-family: 'Inter', sans-serif;
    line-height: 20px;
    letter-spacing: -0.02em;
    font-size: 17px;
    color: #7848ff;
    a {
      text-decoration: underline;
    }
  }
}
