@import '../../../media-queries.scss';

// ===================================================
// Normal item

.item-container {
  position: relative;
  background-color: #111018;
  border-radius: 20px;

  max-width: calc(33% - 17px);
  width: 100%;

  // aspect-ratio: 1/1;

  background-size: contain;
  background-repeat: no-repeat;

  .content {
    font-family: 'Inter', sans-serif;
    font-size: 25px;
    color: white;
    text-align: left;

    padding: 10px 20px 22px 20px;

    margin-top: 58.5%;

    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      font-size: 25px;
      font-weight: 700;
    }

    .user {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .avatar {
        background-size: contain;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .name {
        font-size: 15px;
        line-height: 2;
        text-decoration: underline;
        width: 70%;
      }
    }

    .bottom {
      .labels {
        width: calc(100% - 110px);
        font-size: 12px;
        line-height: 1.5;
        color: #84829d;
        margin-top: 4px;

        .mint {
          color: white;
          font-weight: 700;
        }
        .coll {
          color: #84829d;
        }
      }

      .button-blue {
        width: 120px;
        white-space: nowrap;
      }
    }

    .button {
      &.black {
        background: black;

        &:hover {
          color: white;
        }
        &:disabled {
          opacity: 0.5;
          color: #222;
          cursor: default;

          &:hover {
            color: #ccc;
          }
        }
      }

      width: 100%;
      margin: 0 auto;
      border-radius: 12px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 14px;
      line-height: 17px;
      padding: 15px 30px;
      color: #ccc;
      box-sizing: border-box;
      text-decoration: none;
      box-shadow: none;
    }
  }

  .parent-div {
    display: flex;
    flex-wrap: wrap;
    .user {
      width: 75%;
    }
    .labels {
      width: 25%;
    }
    .mint {
      font-size: 13px;
      font-weight: bold;
      white-space: nowrap;
      padding-top: 7px;
    }
  }
}

@include respond-below(md) {
  .item-container {
    max-width: calc(50% - 15px);
  }
}

@include respond-below(sm) {
  .item-container {
    max-width: 100%;

    .content {
      display: block;
      .parent-div {
        height: 40px;
      }
      .bottom {
        height: 40px;
      }
    }
  }
}
