
.loading-video-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  transition: opacity 500ms;

  video {
    width: 100%;
    height: 100%;
    display: block;
  }

  &.show {
    opacity: 1;
    // allow fade in animation because transition won't run if .show is already
    // in the element upon append or .show is added too early
    animation: loading-video-overlay-fadein 500ms 1;
  }
}

@keyframes loading-video-overlay-fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}
