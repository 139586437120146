@import '../media-queries.scss';

// .page {
//   padding-top: 90px;
// }

.full-height .page {
  position: absolute;
  width: 100%;
  height: 100%;
  // padding-top: 0;
}

@include respond-below(xs) {
  .full-height .page {
    width: 200%;
    height: 200%;
    transform: scale(0.5, 0.5);
    transform-origin: 0% 0%;
  }
}
