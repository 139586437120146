.tag {
  position: absolute;
  left: 20px;
  top: 15px;

  .bg {
    position: absolute;
    background-color: #aa9d9d;
    border-radius: 5px;
    opacity: 0.2;
    width: 100%;
    height: 100%;
  }

  .tag-content {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;

    .icon {
      background-color: red;
      border-radius: 60px;
      width: 8px;
      height: 8px;

      &.live {
        background-color: #61c280;
      }

      &.register {
        background-color: #ffa51e;
      }
    }
    .label {
      font-family: 'Inter', sans-serif;
      font-size: 10px;
      font-weight: 800;
      color: white;
      text-align: left;
    }
  }
}
