@import '../../../media-queries.scss';

.coll-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px 10px 0 10px;

  form {
    width: 100%;
  }
}

.coll-form-container {
  width: 100%;
}

.coll-form-box-connect {
  display: flex;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  vertical-align: middle;
}

.coll-form-box-input {
  display: flex;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  vertical-align: middle;
}

.coll-form-box-link {
  display: flex;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  vertical-align: middle;
}

.coll-form-column-connect {
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0px;
  flex-grow: 50;
  width: 50%;
  margin-bottom: 10px;

  &.left {
    flex-grow: 55;
  }

  &.right {
    flex-grow: 45;
  }
}

.coll-form-column-input {
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0px;
  flex-grow: 50;
  height: 65px;

  &.left {
    flex-grow: 75;
  }

  &.right {
    flex-grow: 25;
  }
}

.coll-box-link-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  // color: #5c5a75;

  a,
  a:link,
  a:visited {
    &:hover {
      color: white;
    }
  }
}

.coll-form-column-link {
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 16px;

  display: flex;
  flex-direction: column;
  justify-content: left;

  padding: 0px;
  width: 50%;
  height: 30px;

  vertical-align: middle;

  &.left {
    width: 18%;
    padding-left: 5px;
  }

  &.right {
    width: 75%;
    vertical-align: middle;
  }
}

.coll-form-btn {
  padding: 14px 10px;
  background: linear-gradient(91.12deg, #6347f6 0.28%, #a32bf5 92.14%);
}

.coll-form-btn-container {
  margin: 0 auto 25px;
}

.coll-form-connect-btn {
  width: 100%;
  &.mint-btn-cta {
    border-radius: 0 0 12px 12px;
    font-size: 18px;
  }
}

.coll-form-submit-btn {
  padding: 14px 10px;
  width: 100%;
  background: linear-gradient(91.12deg, #6347f6 0.28%, #a32bf5 92.14%);
  margin: 15px auto 10px auto;
  &.mint-btn-cta {
    font-size: 18px;

    &:disabled {
      .arrow-icon {
        filter: invert(1) opacity(0.5);
      }
    }
  }
}

.coll-form-title {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  color: white;
}

.coll-form-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #5c5a75;
  padding-top: 3px;

  vertical-align: middle;
  text-align: left;
}

.coll-form-twitter-title {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: #5c5a75;
  // padding-top: 1px;

  // vertical-align: middle;
  text-align: left;
}

.coll-form-twitter-link {
  font-size: 14px;
  line-height: 20px;
  // vertical-align: middle;
  text-align: left;
  // margin-top: 2px;
}

.coll-form-follow-links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.coll-form-error-msg {
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #fd5d5d;
  word-break: break-all;
}

.coll-form-success-msg {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #8afd5d;
  text-align: center;
}

.modal-fade {
  display: flex !important;
  align-items: center;
}

.center-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  background: whitesmoke;
}

.modal-title {
  font-family: 'Inter', sans-serif;
  color: whitesmoke;
  font-size: 20px;
}

.large-spinner {
  width: 100%;
  filter: invert(1);
}

.inter {
  font-family: 'Inter', sans-serif;
  text-align: left;
  width: 100%;
  font-size: 20px;
  line-height: 21px;
}

.no-mouse {
  pointer-events: none;
}

@include respond-below(lg) {
  .coll-form-follow-links {
    flex-direction: column;
  }
}

.submit-arrow {
  float: right;
  margin-right: -13px;
}

.main-con {
  background: black;
}
